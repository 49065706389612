<template>
  <div class="columns is-vcentered is-centered">
    <div class="column is-6">
      <div v-show="alert" class="notification is-danger is-light">
        <strong>Link expirado!</strong><br />
        <router-link to="/recover_password">Enviar outro</router-link>
      </div>
      <div v-show="!alert">
        <h1 class="title">Cadastrar nova Senha</h1>
        <form @submit.prevent="onSubmit">
          <div class="field">
            <label class="label">Nova senha</label>
            <div class="control">
              <input
                v-model="user.password"
                class="input"
                type="password"
                placeholder="Nova senha"
                required
              />
            </div>
            <p class="help is-danger">{{ errors[0] }}</p>
          </div>

          <div class="level">
            <div class="field is-grouped">
              <div class="control">
                <button :disabled="request" class="button is-warning">
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  components: {},
  data() {
    return { user: {}, request: false, alert: false, token: "" };
  },
  created() {
    this.request = false;
    this.alert = false;
    this.tokenPassword = this.$route.params.tokenPassword;
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.request = true;
        this.$http
          .post("auth/reset_password", {
            password: this.user.password,
            tokenPassword: this.tokenPassword,
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data == "ne") {
                this.alert = true;
              } else {
                this.$router.push("/");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
  },
};
</script>
